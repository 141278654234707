namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $hyoo_case_property_snippet, {

		padding: [ 0 , rem(.25) ],
		whiteSpace: 'pre-line',
		flex: {
			grow: 1,
		},

	} )

}
