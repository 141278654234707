namespace $.$$ {

	const { rem } = $mol_style_unit
	
	$mol_style_define( $hyoo_case_entity_page, {

		Config: {
			padding: 0,
		},

		Snippet: {
			textShadow: '0 0',
		},
		
		Body: {
			padding: 0,
		},

	} )

}
